/* 기본 스타일 설정 */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  /* background-image: url("img/background.jpg"); */
  background-size: cover; /* 배경 이미지가 화면을 덮도록 설정 */
  background-position: center; /* 배경 이미지를 가운데 정렬 */
  background-repeat: no-repeat; /* 배경 이미지 반복 금지 */
}

/* 타이틀 스타일 */
.title {
  text-align: center;
  margin-top: 20px;
  font-size: 36px;
  color: #333;
}

/* 메뉴 컨테이너 스타일 */
.menu-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.menuZ,
.menuA,
.menuB {
  margin: 0 5px;
  padding: 10px 5px;
  background-color: #007bff;
  color: rgb(0, 0, 0);
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

/* 메뉴 호버 시 배경색 변경 */
.menuA:hover,
.menuB:hover {
  background-color: #0056b3;
}

/* 서브메뉴 컨테이너 스타일 */
.submenu-container {
  position: absolute;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

/* 서브메뉴 스타일 */
.submenu {
  padding: 5px 10px;
  cursor: pointer;
}

/* 서브메뉴 호버 시 배경색 변경 */
.submenu:hover {
  background-color: #e9ecef;
}

/* 선택된 메뉴 표시 스타일 */
.selected-menu {
  text-align: center;
  font-size: 18px;
  color: #555;
}

/* Swiper 스타일 */
.mySwiper {
  margin-top: 40px;
}

.swiper-slide-image {
  width: 100%;
  height: auto;
  max-height: 500px;
}

.machine1-slide {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

/* 푸터 스타일 */
.footer {
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  font-size: 16px;
  color: #555;
  margin-top: 50px;
}

.footer a {
  color: #007bff;
  text-decoration: none;
  margin: 0 5px;
}

.footer a:hover {
  text-decoration: underline;
}

/* 지도 컨테이너 스타일 */
.map-container {
  width: 100%;
  height: 400px;
  margin-top: 40px;
}

/* 전체 배경색 스타일 */
.whole {
  background-color: #ffffff;
  text-align: center; /* 텍스트를 가운데 정렬 */
  margin: 0 auto; /* 부모 컨테이너 내에서 div 자체를 가운데 정렬 */
  width: 60%;
}

.red {
  color: rgb(255, 255, 255);
  background-color: #970000;
}

/* 반응형 디자인 - 모바일용 */
@media (max-width: 768px) {
  .title {
    font-size: 28px;
  }

  .menuA,
  .menuB {
    padding: 8px 16px;
  }

  .submenu-container {
    width: 120px;
  }

  .submenu {
    width: 80%; /* 전체 너비 사용 */
    margin-right: 0; /* 오른쪽 마진 제거 */
    margin-bottom: 10px; /* 하단 마진 추가 */
  }

  .swiper-slide-image {
    max-height: 500px;
  }

  .machine1-slide {
    max-height: 200px;
    width: 100px;
    background-color: #bbddff;
  }

  .map-container {
    height: 300px;
  }

  .whole {
    background-color: #ffffff;
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  .subtitle {
    width: 90%;
    text-align: center; /* 텍스트를 가운데 정렬 */
    margin: 0 auto; /* 부모 컨테이너 내에서 div 자체를 가운데 정렬 */
    background-color: rgb(255, 255, 255);
  }

  .red {
    color: rgb(255, 255, 255);
    background-color: #970000;
  }

  .machine {
    font-size: 15px;
  }
}

/* CSS 변수 및 반응형 슬라이드 설정 */
:root {
  --swiper-slides-preview-desktop: 6;
  --swiper-slides-preview-mobile: 3;
}

/* 기본 슬라이드 preview 개수 */
.swiper-container {
  --swiper-slides-preview: var(--swiper-slides-preview-desktop);
}

/* 모바일용 슬라이드 preview 개수 */
@media (max-width: 768px) {
  .swiper-container {
    --swiper-slides-preview: var(--swiper-slides-preview-mobile);
  }
}
